import * as React from "react";
import { graphql } from "gatsby";
import Styled from "styled-components/macro";
import Meta from "../../../components/meta";
import Layout from "../../../components/layout/Layout";
import HomeConversion from "../../../components/pages/home/HomeConversion";
import HomeTemplates from "../../../components/pages/home/HomeTemplates";
import HomePrototyping from "../../../components/pages/home/HomePrototyping";
import HomeFeedback from "../../../components/pages/home/HomeFeedback";
import HomeStyling from "../../../components/pages/home/HomeStyling";
import HomeIdeation from "../../../components/pages/home/HomeIdeation";
import HomeWireframe from "../../../components/pages/home/HomeWireframe";
import HomeBrainstorming from "../../../components/pages/home/HomeBrainstorming";
import HeaderBanner from "../../../components/pages/product/HeaderBanner";
import HomeFeatured from "../../../components/pages/home/HomeFeatured";
import Shoutout from "../../../components/pages/signup/Shoutout";
import Content from "../../../components/layout/Content/Content";

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;

const Home = ({ data }) => (
  <Layout>
    <Meta
      title="Uizard: Design app & web mockups | Design Made Easy | Uizard"
      description="Design mobile apps, web apps, websites, and desktop software in minutes. If you can use Google Slides, Keynote, or PowerPoint, you can use Uizard!"
      url="/pr/product-mockups/"
      noindex={true}
    />

    <HeaderBanner
      tagline="Design app & web mockups"
      headline1="If you can use Google Slides, Keynote, or Powerpoint, you can use Uizard!"
      headline2="Quickly build interactive prototypes for websites and apps."
      imageA={data.bannerImageA.childImageSharp}
    />

    <HomePrototyping
      tagline="Prototyping Tool"
      headline="Rapid prototyping, we mean it!"
      description="It should only take seconds to test an idea, not days! Drag-and-drop
      components and templates to quickly and easily design mobile apps, web apps, websites,
      and desktop software. Save time and take control of your ideas."
      imageA={data.prototypingImageA.childImageSharp}
      imageB={data.prototypingImageA.childImageSharp}
      imageAlt="Drag and drop pre-made templates and components to design interactive prototypes in Uizard."
    />

    <HomeWireframe
      tagline="UX Tool"
      headline="Focus on what matters"
      description="Turn wireframe mode on at any time to render your project in low-fidelity.
      Use wireframe mode to take a perspective that enables you to create great user flows and rewarding user experiences."
      imageA={data.wireframeImageA.childImageSharp}
      imageB={data.wireframeImageB.childImageSharp}
      imageAlt="Uizard supports wireframe mode to render designs in low-fidelity."
      videoName="uizard-low-fi-mode"
      link="/wireframing/"
    />

    <HomeIdeation
      tagline="Ideation Tool"
      headline="Experiment at the speed of thought"
      description="Test different styles instantly with the power of Uizard themes.
      Use themes to experiment with your team in a design sprint, to adapt the style of your design for
      different audiences, or simply to explore ideas!"
      imageAlt="Uizard supports theming to render designs in high-fidelity."
      videoName="uizard-theme-switcher"
      imageA={data.ideationImageA.childImageSharp}
      imageB={data.ideationImageB.childImageSharp}
    />

    <HomeTemplates
      tagline="Templates"
      headline="Never start from scratch"
      description="Everyone can create professional designs with Uizard. Use pre-made
      or personalized templates with Uizard's drag-and-drop features to bring your ideas to
      life in minutes. Never fear the blank screen again!"
      imageA={data.templatesImageA.childImageSharp}
      imageB={data.templatesImageB.childImageSharp}
      imageAlt="Image of a team of users using pre-made templates in Uizard."
      link="/templates/"
    />

    <HomeConversion
      tagline="Beyond wireframing"
      headline="Import and transform your wireframes — automagically"
      description="Whether you and your team prefer to sketch your ideas on paper, whiteboard,
        tablet, or in a wireframing tool, Uizard can transform your scribbles and mockups into
        digital screens ready to edit, customize, and use in your project."
      imageA={data.conversionImageA.childImageSharp}
      imageB={data.conversionImageB.childImageSharp}
      imageAlt="Transform rough wireframe sketches to digital screens with Uizard."
      link="/design-assistant/"
    />

    <HomeStyling
      headline="Create beautiful themes effortlessly"
      tagline="Styling"
      description="Extract themes and components from your company's design system, website, style guide,
      or brand identity. Quickly generate unique themes from your favorite inspirations, such as pictures,
      screenshots, moodboards."
      imageA={data.stylingImageA.childImageSharp}
      imageB={data.stylingImageB.childImageSharp}
      imageAlt="Uizard can extract styles and components from URLs, Sketch files, and images."
      link="/themes/"
    />

    <HomeFeedback
      tagline="Real-time collaboration"
      headline="Collaborate with anyone, anywhere, anytime"
      description="Remote work is the new reality. Luckily, collaborating with your team
        in Uizard is fast and easy. Just share a link to your project and start collaborating
        immediately with your colleagues and your customers straight from your web browser.
        No software install required, just pure productivity."
      imageA={data.feedBackImageA.childImageSharp}
      imageB={data.feedBackImageB.childImageSharp}
      imageAlt="Collaborate in real-time with your entire team in Uizard."
    />

    <HomeBrainstorming
      headline="Good ideas can come from anyone"
      tagline="Brainstorming Tool"
      description="Building great products is a team effort.
      That's why a design tool should empower everyone in your team!
      With Uizard, anyone can easily design clickable animated prototypes with real interactive
      components regardless of whether or not they have a design or technical background."
      imageA={data.brainstormingImageA.childImageSharp}
      imageB={data.brainstormingImageB.childImageSharp}
      imageAlt="Image of a team collaborating in Uizard prototyping editor."
    />

    <HomeFeatured />

    <ContentExtended>
      <Shoutout
        headline="Easy design for everyone"
        description="Bring your vision to life in minutes"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </ContentExtended>
  </Layout>
);

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    bannerImageA: file(
      relativePath: { eq: "product/uizard-product-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    bannerImageB: file(
      relativePath: { eq: "product/uizard-drag-and-drop-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    prototypingImageA: file(
      relativePath: { eq: "home/prototyping-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 722, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    prototypingImageB: file(
      relativePath: { eq: "home/prototyping-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    conversionImageA: file(
      relativePath: { eq: "home/conversion-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 938, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    conversionImageB: file(
      relativePath: { eq: "home/conversion-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    templatesImageA: file(relativePath: { eq: "home/templates-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 2260, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    templatesImageB: file(relativePath: { eq: "home/templates-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    stylingImageA: file(relativePath: { eq: "home/styling-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 2120, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    stylingImageB: file(relativePath: { eq: "home/styling-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 1290, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    ideationImageA: file(relativePath: { eq: "home/ideation-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    ideationImageB: file(relativePath: { eq: "home/ideation-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeImageA: file(relativePath: { eq: "home/wireframe-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1230, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeImageB: file(relativePath: { eq: "home/wireframe-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    brainstormingImageA: file(
      relativePath: { eq: "home/brainstorming-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    brainstormingImageB: file(
      relativePath: { eq: "home/brainstorming-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1326, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    feedBackImageA: file(relativePath: { eq: "home/feedback-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1462, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    feedBackImageB: file(relativePath: { eq: "home/feedback-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 774, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
